export default `
    @font-face {
    font-family: 'boldenregular';
    src: url('/fonts/bolden-regular-webfont.woff2') format('woff2'),
         url('/fonts/bolden-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

  @font-face {
    font-family: 'Project44Lite';
    src: url('/fonts/Project44LiteLight-Regular.eot');
    src: url('/fonts/Project44LiteLight-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Project44LiteLight-Regular.woff2') format('woff2'),
        url('/fonts/Project44LiteLight-Regular.woff') format('woff'),
        url('/fonts/Project44LiteLight-Regular.svg#Project44LiteLight-Regular') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Project44Lite';
    src: url('/fonts/Project44LiteRegular-Rg.eot');
    src: url('/fonts/Project44LiteRegular-Rg.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Project44LiteRegular-Rg.woff2') format('woff2'),
        url('/fonts/Project44LiteRegular-Rg.woff') format('woff'),
        url('/fonts/Project44LiteRegular-Rg.svg#Project44LiteRegular-Rg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
`

