import React from "react"
import {Global} from '@emotion/react'
import { ChakraProvider,  CSSReset,  extendTheme } from "@chakra-ui/react"
import myTheme from "./src/styles/theme"
import fonts from "./src/styles/fonts"

const theme = extendTheme(myTheme)
export default ({ element }) => {
    return (
        <ChakraProvider theme={theme}>
            <CSSReset />
            <Global styles={fonts} />
            {element}
        </ChakraProvider>
    )
}


